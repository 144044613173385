import React, { useEffect } from "react"
import { Link } from "gatsby"
import SEO from "../components/SEO" 
import Logo from "../components/logo" 

export default function Lost() {

  useEffect(() => {
    const nextButton = document.querySelector("aside .right-0")
    const prevButton = document.querySelector("aside .left-0")

    const keyBoardNavigation = (e) => {
      if(e.code === "ArrowRight" && nextButton) {
        nextButton.click()
      } else if(e.code === "ArrowLeft" && prevButton) {
        prevButton.click()
      }
    }

    window.addEventListener("keyup", keyBoardNavigation, {
      capture: true,
      once: true
    })
  }, [])

  return (
    <>
      <SEO />
      <Logo />
        <main className="entry">
          <h2>We act as though we're lost to justify changing direction.</h2>
          <div className="time tracking-wide row-start-1">
            4/0/4
          </div>
        </main>
        <aside>
          <Link className="grid items-center absolute bottom-10 sm:inset-y-1/2 left-0 sm:transform sm:-translate-y-1/2 h-20 w-10 sm:h-28 sm:w-14 text-white" to={"/"}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            <span className="sr-only">Today</span>
          </Link>
        </aside>
    </>
  )
}
